import { FC, ReactElement, useRef } from 'react';
import { FeedHandle } from '../feed/feed.tsx';
import { useScrollParent } from '../scroll-parent-context.tsx';
import { PostFeed, PostFeedProps } from './post-feed.tsx';

export const SCROLL_LOCK_DELTA = 180; // ~60% of default post height

export const SubscribedPostFeed: FC<
  PostFeedProps & {
    subscription?: (onData: () => void) => ReactElement;
    loading?: boolean;
    newPostsButtonClassName?: string;
    outdated?: boolean;
  }
> = ({
  subscription,
  loading,
  newPostsButtonClassName,
  outdated,
  ...props
}) => {
  const { scrollParent } = useScrollParent();

  const feedRef = useRef<FeedHandle>(null);

  return (
    <>
      <PostFeed
        feedRef={feedRef}
        style={{
          opacity: loading ? 0.5 : '',
        }}
        {...props}
      />

      {subscription
        ? subscription(() => {
            if ((scrollParent?.scrollTop || 0) > SCROLL_LOCK_DELTA) {
              feedRef.current?.prepend((prev) => prev + 1);
            }
          })
        : null}
    </>
  );
};
